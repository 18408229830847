import "../styles/custom.scss";

import { Col, Container, Image, Nav, Row } from "react-bootstrap";

import API2SQL from "./API2SQL";
import Centered from "./Centered";
import { Link } from "gatsby";
import Logo from "../images/blueonwhite.png";
import RLink from "./RLink";
import React from "react";
import staticData from "./staticData";

export default function FooterSection() {
  return (
    <Container fluid className="bg-dark text-light p-3 pt-4">
      <Row className="justify-content-center align-items-center">
        <Col>
          <Centered>
            <Nav
              defaultActiveKey="/"
              className="flex-row justify-content-start justify-content-sm-around "
            >
              {[
                [
                  ["/contact", "Contact Us"],
                  ["/terms", "Terms of Use"],
                  ["/privacy", "Privacy Policy"],
                  ["/careers", "Careers"],
                ],
                [
                  [staticData.api2sql, API2SQL],
                  [staticData.docs("/docs/faq.html"), "FAQ"],
                  [staticData.dbsync, "DB Sync"],
                  [staticData.httpsync, "Http Sync"],
                  [staticData.docs("/integrations/index/"), "Supported Integrations"],
                ],
                [
                  [staticData.docs(""), "Documentation"],
                  ["https://github.com/webhookdb/webhookdb-cli", "WebhookDB CLI"],
                  ["https://status.webhookdb.com", "Status"],
                  ["/blog", "Blog"],
                ],
              ].map((navs) => (
                <div className="d-flex flex-column" key={navs[0][0]}>
                  {navs.map(([href, text, rest]) => (
                    <Nav.Link
                      key={href}
                      eventKey={href}
                      className="text-light"
                      href={href}
                      as={RLink}
                      {...rest}
                    >
                      {text}
                    </Nav.Link>
                  ))}
                </div>
              ))}
            </Nav>
          </Centered>
          <Row className="justify-content-center my-4">
            <Link to="/">
              <Image src={Logo} />
            </Link>
          </Row>
          <Row className="justify-content-center my-3">
            <p> © {new Date().getFullYear()}</p>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
