import { Col, Row } from "react-bootstrap";

import React from "react";
import clsx from "clsx";

export default function Centered({ className, children }) {
  return (
    <Row className={clsx("justify-content-center bounded", className)}>
      <Col md={12} lg={10}>
        {children}
      </Col>
    </Row>
  );
}
