export default {
  opensource: {
    title: "Open Source",
    desc: "Run WebhookDB yourself, with full control and customization.",
    promise: "100x less effort than other integration tools.",
    price: "Free",
  },
  cloud: {
    title: "Cloud Hosted",
    desc: "Managed hosting with simple usage-based pricing. Generous free tier.",
    promise: "100x faster performance than native APIs.",
    price: "Free (In Beta)",
  },
  enterprise: {
    title: "Enterprise",
    desc: "Adds advanced integrations for Google, Plaid, Microsoft, and more.",
    promise: "10x less expensive and faster to market than DIY.",
    price: "$10,000/year or $30,000 one-time",
  },
};
