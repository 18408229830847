const docs = (tail) => `https://docs.webhookdb.com${tail || ""}`;

export default {
  announcementBlog: `https://lithic.tech/blog/2022-03/announcing-webhookdb`,
  introVideo: "https://fast.wistia.com/embed/medias/lrox7uw103",
  repo: "https://github.com/webhookdb/webhookdb",
  docs,
  getStarted: docs("/docs/getting-started/"),
  dbsync: docs("/docs/integrating/dbsync.html"),
  httpsync: docs("/docs/integrating/httpsync.html"),
  api2sql: docs("/concepts/api2sql/"),
  selfHosting: docs("/docs/operating-webhookdb/self-hosting.html"),
};
