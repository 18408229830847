import "../styles/custom.scss";

import { Image, Nav, NavDropdown, NavLink, Navbar } from "react-bootstrap";

import { IoLogoGithub } from "react-icons/io5";
import Lead from "./Lead";
import Logo from "../images/whiteonwhite.png";
import RLink from "./RLink";
import React from "react";
import { SafeExternalLink } from "./links";
import clsx from "clsx";
import pricingTiers from "../modules/pricingTiers";
import staticData from "./staticData";

const expand = "lg";

export default function NavigationBar() {
  const itemCls = `align-self-end align-self-${expand}-center text-right`;
  const [pricingHref, pricingActive] = useActivePath("/pricing");
  const [blogHref, blogActive] = useActivePath("/blog");
  const [dlHref, dlActive] = useActivePath("/terminal");
  const [contactHref, contactActive] = useActivePath("/contact");
  return (
    <div className="bg-primary sitenav">
      <Navbar bg="primary" variant="dark" expand={expand} className="pl-2 pr-4 bounded">
        <Nav.Link href="/" as={RLink}>
          <Image src={Logo} fluid />
        </Nav.Link>
        <div
          className={clsx(
            `d-${expand}-none`,
            "d-flex",
            "flex-grow-1",
            "justify-content-end"
          )}
        >
          <Nav.Item className={itemCls}>
            <Nav.Link
              href={blogHref}
              active={blogActive}
              as={RLink}
              className="nav-link-hover-invert"
            >
              Blog
            </Nav.Link>
          </Nav.Item>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end w-100">
            <NavDropdown
              title="Solutions"
              id="products-dropdown"
              className={clsx(itemCls, "nav-dropdown nav-link-hover-invert")}
              style={{ maxWidth: 300 }}
            >
              <NavDropdownItem
                href="/pricing"
                title={pricingTiers.opensource.title}
                text={pricingTiers.opensource.desc}
              />
              <Sep />
              <NavDropdownItem
                href="/pricing"
                title={pricingTiers.cloud.title}
                text={pricingTiers.cloud.desc}
              />
              <Sep />
              <NavDropdownItem
                href="/pricing"
                title={pricingTiers.enterprise.title}
                text={pricingTiers.enterprise.desc}
              />
              <Sep />
              <NavDropdownItem
                href={staticData.docs("/integrations/index")}
                title="Integrations"
                text="See what integrations are currently supported."
              />
            </NavDropdown>
            <NavDropdown
              title="Developers"
              id="learn-dropdown"
              className={clsx(itemCls, "nav-dropdown nav-link-hover-invert")}
            >
              <NavDropdownItem
                href={staticData.docs()}
                title="Documentation"
                text="Browse all WebhookDB guides and documentation."
              />
              <Sep />
              <NavDropdownItem
                href={staticData.getStarted}
                title="Guide: Intro & Tutorial"
                text="Step-by-step walkthrough to get started syncing and querying data with WebhookDB."
              />
              <Sep />
              <NavDropdownItem
                href={staticData.api2sql}
                title="API2SQL Architecture"
                text="Welcome to a new, superior paradigm for API integration."
              />
              <Sep />
              <NavDropdownItem
                href="/terminal"
                title="Terminal"
                text="Try WebhookDB's CLI directly from your browser."
              />
              <Sep />
              <NavDropdownItem
                href="/download"
                title="Download CLI"
                text="Download the WebhookDB CLI to run locally."
              />
            </NavDropdown>
            <Nav.Item className={itemCls}>
              <Nav.Link
                href={blogHref}
                active={blogActive}
                as={RLink}
                className="nav-link-hover-invert"
              >
                Blog
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={itemCls}>
              <Nav.Link
                href={pricingHref}
                active={pricingActive}
                as={RLink}
                className="nav-link-hover-invert"
              >
                Pricing
              </Nav.Link>
            </Nav.Item>
            <NavSep className={itemCls} />
            <Nav.Item className={itemCls}>
              <Nav.Link
                href={contactHref}
                active={contactActive}
                as={RLink}
                className="nav-link-hover-invert text-nowrap"
              >
                Contact Us
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={clsx(itemCls)}>
              <NavLink
                href={dlHref}
                as={RLink}
                className={clsx("white", "btn btn-outline-light", dlActive && "active")}
              >
                Log In
              </NavLink>
            </Nav.Item>
            <Nav.Item className={itemCls}>
              <NavLink active={false} href={staticData.repo} as={SafeExternalLink}>
                <IoLogoGithub size={32} />
              </NavLink>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

function NavDropdownItem({ title, text, href }) {
  return (
    <NavDropdown.Item href={href} as={RLink} active={false}>
      <Lead className="mb-1">{title}</Lead>
      <p style={{ whiteSpace: "normal" }} className="mb-0">
        {text}
      </p>
    </NavDropdown.Item>
  );
}

function NavSep({ className }) {
  return (
    <Nav.Item className={clsx(className, `d-none d-${expand}-block`, "text-light")}>
      |
    </Nav.Item>
  );
}

function useActivePath(href) {
  const [active, setActive] = React.useState(false);
  React.useEffect(() => {
    setActive(window.location.pathname.replace(/\/$/, "") === href);
  }, []);
  return [href, active];
}

function Sep() {
  return <hr className="mx-3" />;
}
